import React from "react";
import "./Style.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import $ from "jquery";
import Mainbanner from "./Mainbanner.js";
import Transaction from "./Transaction";
import Errorpage from "./errorpage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Mainbanner />} />
          <Route exact path="/transaction" element={<Transaction />} />
          <Route exact path="*" element={<Errorpage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
