import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./App.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Button } from "react-bootstrap";
import { BsCartPlusFill, BsUiChecksGrid } from "react-icons/bs";
import { TbArrowsExchange2 } from "react-icons/tb";
import { BsCartDashFill } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import flashLoanAbi from "./ABI/flashLoanAbi.json";
import aaveTokenAbi from "./ABI/aave-ABI.json";
import Web3 from "web3";
import config from "./config/config";
import abiArray from "./ABI/pairAbi.json";
import * as ethers from "ethers";
import {
  createFlashLoanHistory,
  getAdminFeeAmount,
  getLoanHistory,
} from "./backend_api/authapis";
import axios from "axios";

export default function App(props) {
  const [uniprice, setuniprice] = useState("");
  const [sushiprice, setsushiprice] = useState("");
  const [profit, setprofit] = useState(0);
  const [account, setaccount] = useState("");
  const [loader, setLoader] = useState(false);
  const [isFlickering, setIsFlickering] = useState(false);
  const [usdtPrice, setUsdtPrice] = useState(null);
  const [adminEarnPrice, setAdminEarnPrice] = useState(0);
  const [currentMinAmount, setCurrentMinAmount] = useState("");
  const [currentMaxAmount, setCurrentMaxAmount] = useState("");
  const [routerOne, setRouterone] = useState("UniSwapV2");
  const [routerTwo, setRouterTwo] = useState("SushiSwapV2");
  const [routerNumber, setRouterNumber] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);

  // const getAmount = async () => {
  //   try {
  //     const response = await axios.get(`${config.BACKEND_URL}admin/getAmount`);
  //     // console.log(response.data, "response");
  //     if (
  //       response.data &&
  //       Array.isArray(response.data.data) &&
  //       response.data.data.length === 2
  //     ) {
  //       setCurrentMinAmount(response.data.data[0]);
  //       setCurrentMaxAmount(response.data.data[1]);
  //     } else {
  //       console.log("Invalid response data format");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching amount settings:", error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.binance.com/api/v3/avgPrice?symbol=DAIUSDT"
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setUsdtPrice(data.price);
    } catch (error) {
      //
    }
  };

  const handleSubmit = async () => {
    let walletConn = localStorage.getItem("address");
    const web3js = window.ethereum
      ? new Web3(window.ethereum)
      : new Web3(
          new Web3.providers.HttpProvider(
            "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999"
          )
        );

    const chainId = await (window.ethereum
      ? window.ethereum.request({ method: "eth_chainId" })
      : web3js.eth.net.getId()); // Use web3.eth.net.getId when using the HTTP provider

    if (walletConn !== null) {
      const accounts = await (window.ethereum
        ? window.ethereum.request({ method: "eth_requestAccounts" })
        : web3js.eth.getAccounts()); // Use web3.eth.getAccounts when using the HTTP provider
      setaccount(accounts[0]);

      if (accounts[0] !== "") {
        if (props.data == 0) {
          toast.error("Please enter amount..!");
          return;
        }
        // if (props.data > currentMaxAmount || props.data < currentMinAmount) {
        //   toast.error(
        //     `Minimum amount should be  ${currentMinAmount} and Maximum amount should be ${currentMaxAmount}`
        //   );
        //   return;
        // }
        try {
          setLoader(true);

          // const web3 = web3js;
          // console.log(web3.eth)

          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          // console.log(signer,'signer')
          const aaveTokenContract = new ethers.Contract(
            config.USDT,
            aaveTokenAbi,
            signer
          );

          const aaveBalanceBefore = await aaveTokenContract.balanceOf(
            config.flashLoan_contractAddress
          );
          const amountInWei = web3js.utils.toWei(props.data, "ether");
          const flashcontract = new ethers.Contract(
            config.flashLoan_contractAddress,
            flashLoanAbi,
            signer
          );

          let firstRouter;
          let secondRouter;

          if (routerNumber == 0) {
            firstRouter = config.uniswap;
            secondRouter = config.sushiswapRouter;
          } else {
            firstRouter = config.sushiswapRouter;
            secondRouter = config.uniswap;
          }
          const flashloan = await flashcontract.sampleFlashLoan(
            config.DAI,
            config.USDT,
            amountInWei,
            [firstRouter, secondRouter]
          );

          await flashloan.wait();
          console.log(flashloan, "flashloan--");

          if (flashloan) {
            const aaveBalanceAfter = await aaveTokenContract.balanceOf(
              config.flashLoan_contractAddress
            );

            const difference = parseInt(aaveBalanceAfter - aaveBalanceBefore);

            let payLoad = {
              transactionHash: flashloan.hash,
              userAddress: flashloan.from,
              toAddress: flashloan.to,
              amount: props.data,
              profitAmount: profit,
              received: receiveAmount,
              tokenAddress: config.DAI,
            };

            let userData = await createFlashLoanHistory(payLoad);
            // console.log(userData, "userdata");
            if (userData.status == false) {
              toast.error("Something went wrong from database");
            }
            // console.log("userData",userData);
            toast.success("Executed Successfully");
            setLoader(false);
            await profitrefresh();
          }
        } catch (error) {
          setLoader(false);
          toast.error("This transaction will be Loss");
          console.log(error, "flashLoanCall error");
        }
      } else {
        setLoader(false);
        toast.error("Please connect your wallet...");
      }
    } else {
      // console.log("wallet not connect");
      toast.error("Please connect your wallet...");
    }
  };
  const getPairPrice = async () => {
    try {
      let priceRouter;

      if (routerOne == "UniSwapV2") {
        priceRouter = config.Uniprice;
        // console.log('uniworking',priceRouter)
      } else {
        priceRouter = config.sushiprice;
        // console.log('sushiworking',priceRouter)
      }
      // const web3js = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/093cf180272947989f95a835644d5999"));
      const web3js = window.ethereum
        ? new Web3(window.ethereum)
        : new Web3(
            new Web3.providers.HttpProvider(
              "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999"
            )
          );

      const UniSwapcontract = new web3js.eth.Contract(abiArray, priceRouter);

      const findContractPairPrice = await UniSwapcontract.methods
        .getReserves()
        .call();

      const reserve0 = parseInt(findContractPairPrice[0]);
      const reserve1 = parseInt(findContractPairPrice[1]);
      // console.log(reserve0, reserve1);
      const usdtDecimals = 6;
      const daiDecimals = 18;
      const token0 = await UniSwapcontract.methods.token0().call();
      const token1 = await UniSwapcontract.methods.token1().call();
      let daiPriceInUsdt;

      if (token0.toLowerCase() == config.DAI.toLowerCase()) {
        daiPriceInUsdt =
          (reserve1 * 10 ** daiDecimals) / (reserve0 * 10 ** usdtDecimals);
      } else {
        daiPriceInUsdt =
          (reserve0 * 10 ** daiDecimals) / (reserve1 * 10 ** usdtDecimals);
      }

      setuniprice(usdtPrice * (props.data * daiPriceInUsdt));
    } catch (error) {
      console.log("getPairPrice error", error);
    }
  };

  function toDecimalPlace(value, decimalPlaces = 12) {
    // Ensure value is a number
    let numberValue = Number(value);
    if (isNaN(numberValue)) {
      throw new Error("Invalid number value");
    }
    // Return the number formatted to the specified decimal places
    return numberValue.toFixed(decimalPlaces);
  }

  function toFixedScifiNum(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }

  function removeTrailingZeros(number) {
    const numberString = number.toString();
    const [integerPart, decimalPart] = numberString.split(".");
    if (!decimalPart || decimalPart.length === 0) {
      return integerPart;
    }
    const trimmedDecimalPart = decimalPart.replace(/0+$/, "");
    if (trimmedDecimalPart.length === 0) {
      return integerPart;
    }
    return `${integerPart}.${trimmedDecimalPart}`;
  }

  const getsushiprice = async () => {
    try {
      // const web3js = window.ethereum
      //   ? new Web3(window.ethereum)
      //   : new Web3(
      //       new Web3.providers.HttpProvider(
      //         "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999"
      //       )
      //     );
      // const web3js = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/093cf180272947989f95a835644d5999"));
      const web3js = new Web3(window.ethereum);
      let priceRouter;

      if (routerTwo == "SushiSwapV2") {
        priceRouter = config.sushiprice;
      } else {
        priceRouter = config.Uniprice;
      }

      const SuchiContract = new web3js.eth.Contract(abiArray, priceRouter);

      const findContractPairPrice = await SuchiContract.methods
        .getReserves()
        .call();

      const reserve0 = parseInt(findContractPairPrice[0]);
      const reserve1 = parseInt(findContractPairPrice[1]);
      const usdtDecimals = 6;
      const daiDecimals = 18;
      const token0 = await SuchiContract.methods.token0().call();

      let daiPriceInUsdt;
      if (token0.toLowerCase() == config.DAI.toLowerCase()) {
        daiPriceInUsdt =
          (reserve1 * 10 ** daiDecimals) / (reserve0 * 10 ** usdtDecimals);
      } else {
        daiPriceInUsdt =
          (reserve0 * 10 ** daiDecimals) / (reserve1 * 10 ** usdtDecimals);
      }

      setsushiprice(usdtPrice * (props.data * daiPriceInUsdt));
    } catch (error) {
      console.log("error on catch", error);
      // alert(error)
    }
  };

  const profitcalc = async () => {
    try {
      if (!uniprice || !sushiprice || !props.data) {
        return;
      }

      const web3js = window.ethereum
        ? new Web3(window.ethereum)
        : new Web3(
            new Web3.providers.HttpProvider(
              "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999"
            )
          );

      const findProfitPrice = new web3js.eth.Contract(
        flashLoanAbi,
        config.flashLoan_contractAddress
      );

      const amountToWei = web3js.utils.toWei(props.data, "ether");
      // console.log(props.data, amountToWei);

      let firstRouter;
      let secondRouter;

      if (routerNumber == 0) {
        firstRouter = config.uniswap;
        secondRouter = config.sushiswapRouter;
      } else {
        firstRouter = config.sushiswapRouter;
        secondRouter = config.uniswap;
      }

      const profitAmt = await findProfitPrice.methods
        .estimateDualDexTrade(
          firstRouter,
          secondRouter,
          config.DAI,
          config.USDT,
          amountToWei.toString()
        )
        .call();

      let convertProfit = parseInt(profitAmt);
      let exactPrice = convertProfit / 10 ** 18;
      // console.log(exactPrice, "exactPrice");

      if (props.data > 0) {
        let interestamount = (0.05 * props.data) / 100;
        // console.log(interestamount, "interestamount--");
        const getamount = exactPrice - interestamount;
        let profitAmount = getamount - props.data;
        // console.log(profitAmount, "profitAmount--");

        let convertScifiRes = parseFloat(toFixedScifiNum(profitAmount));
        // console.log(convertScifiRes);

        setprofit(removeTrailingZeros(toDecimalPlace(convertScifiRes)));
        let adminFee = parseFloat(
          toFixedScifiNum((convertScifiRes * config.AdminFee) / 100)
        );
        setAdminEarnPrice(removeTrailingZeros(toDecimalPlace(adminFee)));
        let receive = parseFloat(toFixedScifiNum(convertScifiRes - adminFee));
        setReceiveAmount(removeTrailingZeros(toDecimalPlace(receive)));
      }
    } catch (error) {
      console.log("error", error);
      setprofit(0);
      setAdminEarnPrice(0);
      setReceiveAmount(0);
      // alert(error)
    }
  };

  function myFunction() {
    profitrefresh();

    setTimeout(myFunction, 600000);
  }
  useEffect(() => {
    myFunction();
  }, []);

  useEffect(() => {
    fetchData();
    // getAmount();
    profitcalc();
  }, [account, profit, props.data, uniprice, sushiprice]);

  useEffect(() => {
    init();
  }, [props.data, routerNumber]);

  const init = async () => {
    await getPairPrice();
    await getsushiprice();
  };

  const profitrefresh = async () => {
    setIsFlickering(true);
    await getPairPrice();
    await getsushiprice();
    await profitcalc();
    setIsFlickering(false);
  };

  const routerchange = async () => {
    if (routerNumber == 0) {
      setRouterone("SushiSwapV2");
      setRouterTwo("UniSwapV2");
      setRouterNumber(1);

      // console.log('router11111111111', routerNumber)
    } else {
      setRouterone("UniSwapV2");
      setRouterTwo("SushiSwapV2");
      setRouterNumber(0);
      // console.log('router0000000000', routerNumber)
    }
  };

  return (
    <>
      <ToastContainer />
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        }}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        initialSlide={3}
        modules={[Autoplay, Pagination]}
        className="mySwiper py-5"
      >
        <SwiperSlide>
          <div className="">
            <div className="container px-3 ">
              <div className="row px-3 justify-content-center align-items-center text-center">
                <div className="col d-flex flex-row justify-content-center align-items-center text-center py-2 ">
                  <p className="mb-0 fs-15 fw-800 ">DAI</p>
                  <p className="mb-0 fs-15 fw-800 ">-</p>
                  <p className="mb-0 fs-15 fw-800 ">USDC</p>
                </div>
              </div>
              {isFlickering ? (
                <div className="d-flex flex-row ps-4">
                  <div
                    class="spinner-grow spinner-grow-sm mb-1 me-2 text-center visible-loading"
                    role="status"
                  >
                    <span class="visually-hidden ">Loading...</span>
                  </div>
                  <div
                    class="spinner-grow spinner-grow-sm mb-1 me-2 text-center"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div
                    class="spinner-grow spinner-grow-sm mb-1 text-center"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : props.data > 0 ? (
                <div className="text-center">
                  <div className="p-2 third-column-section-bg">
                    {profit > 0 && props.data ? (
                      <>
                        <p
                          className="mb-1 fs-15 fw-800"
                          style={{ color: "rgb(11 255 5)" }}
                        >
                          Profit : {profit + " DAI"}
                        </p>
                        <p
                          className="mb-0 fs-15 fw-800"
                          style={{ color: "rgb(2 49 217)" }}
                        >
                          Fee : {adminEarnPrice + " DAI"}
                        </p>
                        <p
                          className="mb-0 fs-15 fw-800"
                          style={{ color: "rgb(2 49 217)" }}
                        >
                          Receive : {receiveAmount + " DAI"}
                        </p>
                      </>
                    ) : (
                      <p
                        className="mb-0 fs-15 fw-800"
                        style={{ color: "#eb1515" }}
                      >
                        Loss : {profit + " DAI"}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row  px-0 my-2 row-cols-lg-1 justify-content-between  text-center">
                <div className="col-lg-12  ">
                  <div className="d-flex flex-row justify-content-between align-items-center text-center">
                    <p className="mb-0 fs-15 fw-800  orange-color">
                      Exchange :
                    </p>
                    <p className="mb-0 fs-12 fw-600 p-2">{routerOne}</p>
                    <div>
                      <TbArrowsExchange2
                        className="custom-icons"
                        onClick={routerchange}
                      />
                    </div>

                    <p className="mb-0 fs-12 fw-600 p-2">{routerTwo}</p>
                  </div>
                  <div className="d-flex mt-3 flex-row justify-content-between align-items-center text-center uni-sushi-price">
                    <p className="mb-0 fs-15 fw-800 orange-color">Price</p>
                    {isFlickering == true ? (
                      <div className="d-flex flex-row ps-4">
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 "
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <p className="mb-0 fs-12 fw-600 p-2">
                        {"$" + removeTrailingZeros(toDecimalPlace(uniprice))}
                      </p>
                    )}
                    {isFlickering == true ? (
                      <div className="d-flex flex-row pe-3">
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div
                          class="spinner-grow spinner-grow-sm mb-1 "
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <p
                        className="mb-0 fs-12 fw-600 pe-3"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {"$" + removeTrailingZeros(toDecimalPlace(sushiprice))}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <p className="mb-0 fs-15 fw-800 px-5 pb-2  me-1 violet-color">
                    Action{" "}
                  </p>
                  <div className="d-flex flex-row justify-content-center align-items-center text-center gap-3">
                    {loader == true ? (
                      <div>
                        <button className="btn  small-button-styling-css">
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="btn small-button-styling-css"
                          onClick={handleSubmit}
                        >
                          <BsCartPlusFill className="me-1" /> Execute
                        </button>
                        <button
                          className="btn refresh-button-styling-css ms-2"
                          onClick={profitrefresh}
                        >
                          <BiRefresh className="me-1" />
                          Get Latest price
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row py-2 border-top  justify-content-center align-items-center text-center">
                <div className="col ">
                  <p className="mb-0 fs-13 fw-600">Flash Loan</p>
                  <p className="mb-0 fs-13">$$</p>
                </div>
              </div> */}
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
                    <div className=" input-boxes-full-section-bg-css border">
                        <div className="container px-3 ">
                            <div className="row px-3   justify-content-center align-items-center text-center">
                                   <p className="mb-0 fs-15 fw-800 ">ETH</p>
                                    <p className="mb-0 fs-15 fw-800 ">-</p>
                                  <div className="col d-flex flex-row justify-content-center align-items-center text-center py-2 ">
                                   <p className="mb-0 fs-15 fw-800 ">USDT</p>
                                </div>
                            </div>
                            <div className="row   justify-content-center align-items-center text-center">
                                <div className="col-6 p-2  third-column-section-bg">
                                    <p className="mb-0 fs-15 fw-800">Profit% = 1208%</p>
                                </div>
                            </div>
                            <div className="row  px-0 my-2 row-cols-lg-1 justify-content-between  text-center">
                                <div className="col  ">
                                    <div className="d-flex flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Exchange :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">BalancerV2</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">SushiSwap</p>
                                    </div>
                                    <div className="d-flex mt-3 flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Price :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">1877.42</p>
                                        <p className="mb-0 fs-12 fw-600 p-2 ">1875.40</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-0 fs-15 fw-800 px-5 pb-2  me-1 violet-color">Action </p>
                                    <div className='d-flex flex-row justify-content-center align-items-center text-center'>
                                        <div>
                                            <button className="btn  small-button-styling-css">
                                                <BsCartPlusFill className=' fs-13' /> Buy
                                            </button>
                                        </div>
                                        <div className="mx-2">
                                            <button id="hideshowprofitlossbtn2" className=" btn small-button-styling-css-2">
                                                <BsCartDashFill className=' fs-13' /> Sell
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 border-top  justify-content-center align-items-center text-center">

                                <div className="col ">
                                    <p className="mb-0 fs-13 fw-600">Flash Loan</p>
                                    <p className="mb-0 fs-13 grey-text ">$$</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" input-boxes-full-section-bg-css border">
                        <div className="container px-3 ">
                            <div className="row px-3   justify-content-center align-items-center text-center">
                                <div className="col d-flex flex-row justify-content-center align-items-center text-center py-2 ">
                                    <p className="mb-0 fs-15 fw-800 ">ETH</p>
                                    <p className="mb-0 fs-15 fw-800 ">-</p>
                                    <p className="mb-0 fs-15 fw-800 ">USDT</p>
                                </div>
                            </div>
                            <div className="row   justify-content-center align-items-center text-center">
                                <div className="col-6 p-2  third-column-section-bg">
                                    <p className="mb-0 fs-15 fw-800">Profit% = 1208%</p>
                                </div>
                            </div>
                            <div className="row  px-0 my-2 row-cols-lg-1 justify-content-between  text-center">
                                <div className="col  ">

                                    <div className="d-flex flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Exchange :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">BalancerV2</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">SushiSwap</p>

                                    </div>
                                    <div className="d-flex mt-3 flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Price :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">1877.42</p>
                                        <p className="mb-0 fs-12 fw-600 p-2 ">1875.40</p>

                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-0 fs-15 fw-800 px-5 pb-2  me-1 violet-color">Action </p>
                                    <div className='d-flex flex-row justify-content-center align-items-center text-center'>
                                        <div>
                                            <button className="btn  small-button-styling-css">
                                                <BsCartPlusFill className=' fs-13' /> Buy
                                            </button>
                                        </div>
                                        <div className="mx-2">
                                            <button id="hideshowprofitlossbtn2" className=" btn small-button-styling-css-2">
                                                <BsCartDashFill className=' fs-13' /> Sell
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 border-top  justify-content-center align-items-center text-center">

                                <div className="col ">
                                    <p className="mb-0 fs-13 fw-600">Flash Loan</p>
                                    <p className="mb-0 fs-13 grey-text ">$$</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" input-boxes-full-section-bg-css border">
                        <div className="container px-3 ">
                            <div className="row px-3   justify-content-center align-items-center text-center">
                                <div className="col d-flex flex-row justify-content-center align-items-center text-center py-2 ">
                                    <p className="mb-0 fs-15 fw-800 ">ETH</p>
                                    <p className="mb-0 fs-15 fw-800 ">-</p>
                                    <p className="mb-0 fs-15 fw-800 ">USDT</p>
                                </div>
                            </div>
                            <div className="row   justify-content-center align-items-center text-center">
                                <div className="col-6 p-2  third-column-section-bg">
                                    <p className="mb-0 fs-15 fw-800">Profit% = 1208%</p>
                                </div>
                            </div>
                            <div className="row  px-0 my-2 row-cols-lg-1 justify-content-between  text-center">
                                <div className="col  ">

                                    <div className="d-flex flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Exchange :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">BalancerV2</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">SushiSwap</p>

                                    </div>
                                    <div className="d-flex mt-3 flex-row justify-content-between align-items-center text-center">
                                        <p className="mb-0 fs-15 fw-800  violet-color">Price :</p>
                                        <p className="mb-0 fs-12 fw-600 p-2">1877.42</p>
                                        <p className="mb-0 fs-12 fw-600 p-2 ">1875.40</p>

                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-0 fs-15 fw-800 px-5 pb-2  me-1 violet-color">Action </p>
                                    <div className='d-flex flex-row justify-content-center align-items-center text-center'>
                                        <div>
                                            <button className="btn  small-button-styling-css">
                                                <BsCartPlusFill className=' fs-13' /> Buy
                                            </button>
                                        </div>
                                        <div className="mx-2">
                                            <button id="hideshowprofitlossbtn2" className=" btn small-button-styling-css-2">
                                                <BsCartDashFill className=' fs-13' /> Sell
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 border-top  justify-content-center align-items-center text-center">

                                <div className="col ">
                                    <p className="mb-0 fs-13 fw-600">Flash Loan</p>
                                    <p className="mb-0 fs-13 grey-text ">$$</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}
      </Swiper>
      <div className="mt-2">
          <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mt-1">
                      <p className="fs-13">Instructions: Connect your Metamask wallet. </p>
                      <p className="fs-13">If you are not already in the Sepolia Testnet on your wallet, the application will automatically ask you to switch to the Sepolia Testnet.</p>
                      <p className="fs-13">Keep changing the DAI amount and switch between the available exchanges until the bot determines a profitable trade. </p>
                      <p className="fs-13">If your desired profit is achieved, click on Execute to execute the trade.</p>
                      <p className="fs-13">The bot will automatically buy a flash loan in Aave, execute the trade, payback Aave, and return the profit difference to your Metamask wallet.</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </>
  );
}
