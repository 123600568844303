import React, { useState, useEffect } from "react";
import "./Style.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import $, { event } from "jquery";
import Navbar from "./Navbar.js";
import { IoMdFlash } from "react-icons/io";
import { MdWifiFind } from "react-icons/md";
import { BsLightningFill } from "react-icons/bs";
import { TbMathSymbols } from "react-icons/tb";

import { BsCartPlusFill } from "react-icons/bs";
import { BsCartDashFill } from "react-icons/bs";

import { IoIosRefreshCircle } from "react-icons/io";
import Swiperbox from "./Swiperbox.js";
import config from "./config/config";
import Headernav from "./HeaderNav.js";
import Timer from '../src/images/Performance-History.png'
import Copy from "../src/images/Copy-Symbol.png";
import { toast,ToastContainer } from "react-toastify";

function Mainbanner() {
  const [userAmt, setUserAmt] = useState(0);

  var jq = $.noConflict();
  jq(document).ready(function () {
    jq("#hideshowprofitlossbtn1").click(function () {
      $("#hideshowprofit").toggle();
    });

    jq("#hideshowprofitlossbtn2").click(function () {
      $("#hideshowloss").toggle();
    });
  });

  const handleInput = (event) => {
    let value = event.target.value;
    setUserAmt(value);
  };
  const blockInvalidChar = (event) => {
    return ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
  };

  const handleCopyClick = async (data) => {
    try {
      await navigator.clipboard.writeText(
        `${data }`
      );
      toast.success("Copied");
    } catch (error) {
      toast.error("error");
    }
  };

  return (
    <div>
      <Headernav />
      <div className="container-fluid">
        <div className="row justify-content-center">
          {/* <div className="col-1 ps-0 side-nav-bg-styling border-end ">
            <Navbar />
          </div> */}

          <div className="col-lg-5 ">
            <div className="sidenav-column-section">
              <div className="d-flex flex-row justify-content-center text-center align-items-center">
                {/* <div className="mx-3">
                  <div className="box-icon-styling-css">
                    <IoMdFlash />
                  </div>
                  <p className="fs-10">FlashGap</p>
                </div> */}
                <a href="/transaction" className="text-white">
                  <div className="text-center justify-content-center align-items-center d-flex" style={{flexDirection:"column"}}>
                    <div className="box-icon-styling-css">
                    <img style={{width:"55px"}} src={Timer}></img>
                    </div>
                    <p className="fs-9 mb-0" style={{color:"#cddc39"}}>Performance History</p>
                  </div>
                </a>
              </div>
              {/* <button className=" btn refresh-button-styling-css w-100">
                Refresh Data <IoIosRefreshCircle className='fs-14' />
              </button> */}

<div className="pb-1">
                <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">This Flash loan Bot Demo is running on The Sepolia Test net.</p>
                  <p className="fs-13">Make sure You are on the right network with your Metamask Wallet.</p>
                  <div className="">
                    <p className="fs-13 m-0">Get SepoliaETH for the Gas fee from here:</p>
                    <a style={{color:"#cddc39"}} href="https://www.infura.io/faucet/sepolia " target="blank">https://www.infura.io/faucet/sepolia </a>
                  </div>
                  <div className="mt-3">
                    <p className="fs-13">Use this Token Contract Address to import DAI into your Metamask:</p>
                    <p className="fs-13">0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357<span className="ms-2"><img style={{width:"15px",cursor:"pointer"}} onClick={()=>handleCopyClick('0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357')} src={Copy}></img></span></p>
                  </div>
                  <div>
                    <p className="fs-13">Use this Token Contract Address to import USDC into your Metamask:</p>
                    <p className="fs-13">0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8<span className="ms-2"><img style={{width:"15px",cursor : "pointer"}} onClick={()=>handleCopyClick('0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8')}src={Copy}></img></span></p>
                  </div>
                </div>
              </div>
                {/* <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">Identified Within</p>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>2 Min</option>
                    <option value="1">5 Min</option>
                  </select>
                </div>
                <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">Initial Results</p>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>5</option>
                    <option value="1">10</option>
                  </select>
                </div> */}
              <div className="pt-4 mb-5 border-top">
                {/* <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Symbol</p>
                  <p className="fs-13 border-bottom">{config.network_symbol}</p>
                </div>
                <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Address</p>
                  <div className="border-bottom">
                    <a
                      className="fs-13 blue-color "
                      target="_blank"
                      href={`https://sepolia.etherscan.io/address/${config.DAI}`}
                    >
                      {config.DAI}
                    </a>
                  </div>
                </div> */}
                {/* <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">Newest</p>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Newest</option>
                    <option value="1">Highest Percent</option>
                    <option value="1">Lowest Percent</option>
                  </select>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-7 pt-3">
            <div className="sidenav-column-section-2">
              {/* <div className="">
                <p className="mb-1 fs-13 fw-500">Notice</p>
                <p className="fs-13 grey-text">
                  FlashGap is work-in-progress. Right now you can fully execute
                  flashloans from this page.
                  <span className="fw-600">
                    !!! WARNING: BE CAREFUL OF GAS FEES - WATCH THE VIDEO BELOW
                    FIRST !!!
                  </span>
                </p>
              </div>
              <div className="">
                <p className="mb-1 fs-13 fw-500">Instructions</p>
                <p className="fs-13 grey-text">
                  Video instructions
                  <span className="fw-600 blue-color"> Here</span>
                </p>
              </div> */}
              <div className="row number-input-bg-styling border align-items-center">
                <div className="col-lg-3">
                  <p className="mb-1 fs-13 fw-500" >Enter The DAI Amount</p>
                </div>
                <div className="col-lg-4">
                  <input
                    className="form-control form-control-new-1"
                    placeholder="Enter Amount"
                    type="text" // Corrected type attribute
                    pattern="^-?[0-9]\d*\.?\d*$"
                    onKeyPress={(event) => {
                      if (
                        event.key === "." &&
                        event.target.value.includes(".")
                      ) {
                        event.preventDefault(); // Prevent entering multiple decimal points
                      }
                      const keyCode = event.which || event.keyCode;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^[0-9\b.]$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleInput}
                  />
                </div>
              </div>
              {/* <div className="mt-4 input-boxes-full-section-bg-css border">
              <div className="container-fluid px-3 ">
                <div className="row px-3  border-bottom row-cols-lg-2 justify-content-between align-items-center text-center">
                  <div className="col-4 py-2 border-end">
                    <p className="mb-0 fs-15 fw-800">3.08 %</p>
                  </div>
                  <div className="col-8 py-2">
                    <div className="d-flex flex-row justify-content-between">
                      <p className="mb-0 fs-13 grey-text ">Amount In: 0.00001</p>
                      <input type="range" min="1" max="100" value="50" />
                      <p className="mb-0 fs-13 blue-color ">0.0161515 WETH</p>
                    </div>
                  </div>

                </div>
                <div className="row  px-0 my-2 row-cols-lg-2 justify-content-between align-items-center text-center">
                  <div className="col-10  border-end">
                    <div className="d-flex flex-row justify-content-between">
                      <p className="mb-0 fs-10 px-5 py-2 me-1 border violet-color">UniswapV3</p>
                      <p className="mb-0 fs-10 px-5 py-2 border ">WETH</p>
                      <p className="mb-0 fs-10 px-5 py-2 border ">0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2</p>

                    </div>
                    <div className="d-flex mt-3 flex-row justify-content-between">
                      <p className="mb-0 fs-10 px-5 py-2 border violet-color">UniswapV3</p>
                      <p className="mb-0 fs-10 px-5 py-2 border ">WETH</p>
                      <p className="mb-0 fs-10 px-5 py-2 border ">0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2</p>

                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <button className="btn  small-button-styling-css">
                        <BsLightningFill /> Trade
                      </button>
                    </div>
                    <div className="mt-2">
                      <button id="hideshowprofitlossbtn1" className=" btn small-button-styling-css-2 ">
                        <TbMathSymbols /> Test
                      </button>
                      <div id="hideshowprofit" className="mt-3">
                        <p className="mb-0 fs-10 fw-600">Profit</p>
                        <p className="mb-0 fs-10 fw-800 green-text">+0.0066</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-2 border-top row-cols-lg-3 justify-content-between align-items-center text-center">
                  <div className="col border-end">
                    <p className="mb-0 fs-13 fw-600">0.01665</p>
                    <p className="mb-0 fs-13 grey-text ">amount in</p>
                  </div>
                  <div className="col border-end">
                    <p className="mb-0 fs-13 fw-600">0.01665</p>
                    <p className="mb-0 fs-13 grey-text ">amount out</p>
                  </div>
                  <div className="col ">
                    <p className="mb-0 fs-13 fw-600">0.01665</p>
                    <p className="mb-0 fs-13 grey-text ">profit</p>
                  </div>
                </div>
              </div>
            </div> */}

              <div className="">
                <Swiperbox data={userAmt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mainbanner;
