import React, { useEffect, useState } from "react";
import "./Style.css";
import Headernav from "./HeaderNav.js";
import { IoMdFlash } from "react-icons/io";
import { MdWifiFind } from "react-icons/md";
import config from "./config/config";
import { getLoanHistory } from "./backend_api/authapis";
import moment from "moment";
import aaveLogo from "./images/aave-logo.png";
import DataTable from "react-data-table-component";
import vusdlogo from "./images/VUSD.png";
import flash from "../src/images/Switch-to-Dashboard.png"
import Copy from "../src/images/Copy-Symbol.png";
import { toast,ToastContainer } from "react-toastify";


function App() {
  const [loanHistoryData, setLoanHistoryData] = useState([]);
  useEffect(() => {
    loanHistory();
  }, []);

  const columns = [
    {
      name: "Asset",
      selector: (row) => {
        return (
          <>
            <img
              className="new-trans-assest-image"
              src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
              alt="logo"
            />{" "}
            DAI
          </>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Profit",
      selector: (row) => {
        const reward = row.profitAmount;
        if (reward) {
          return `${reward}DAI`;
        }
        return "-";
      },
    },
    {
      name: "Receive",
      selector: (row) => {
        const receive = row.receivedAmount;
        if (receive) {
          return `${receive}DAI`;
        }
        return "-";
      },
    },

    {
      name: "Hash",
      selector: (row) => {
        return (
          <a
            href={config.transactionHashUrl + row.transactionHash}
            target="_blank"
            className="blue-color"
          >
            {row.transactionHash.substring(0, 5) +
              "..." +
              row.transactionHash.substring(row.transactionHash.length - 4)}
          </a>
        );
      },
    },

    {
      name: "Protocol",
      selector: (row) => {
        return (
          <div>
            <img className="new-trans-assest-image" src={aaveLogo} alt="logo" />{" "}
            AAVE V3
          </div>
        );
      },
    },
    {
      name: "Date",
      width: "200px",
      selector: (row) => {
        return (
          <div>{moment.utc(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</div>
        );
      },
    },
  ];

  const handleCopyClick = async (data) => {
    try {
      await navigator.clipboard.writeText(
        `${data }`
      );
      toast.success("Copied");
    } catch (error) {
      toast.error("error");
    }
  };

  const loanHistory = async () => {
    try {
      let loanHistory = await getLoanHistory();
      if (loanHistory.status) {
        setLoanHistoryData(loanHistory.data);
      } else {
        // console.log("Something went wrong");
      }
    } catch (error) {
      // console.log("getLoanHistory error", error);
    }
  };
  return (
    <div>
      <Headernav />
      <div className="container-fluid">
      <ToastContainer />
        <div className="row justify-content-center">
          <div className="col-lg-5 ">
            <div className="sidenav-column-section">
              <div className="d-flex flex-row justify-content-center text-center align-items-center">
                <a href="/" className="text-white">
                  <div className="mx-3" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                    <div className="box-icon-styling-css">
                     <img style={{width:"55px"}}  src={flash}></img>
                    </div>
                    <p className="fs-9 mt-2 mb-0" style={{color:"#cddc39"}}>Switch To Dashboard</p>
                  </div>
                </a>
              </div>
              <div className="pb-1">
                <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">This Flash loan Bot Demo is running on The Sepolia Test net.</p>
                  <p className="fs-13">Make sure You are on the right network with your Metamask Wallet.</p>
                  <div className="">
                    <p className="fs-13 m-0">Get SepoliaETH for the Gas fee from here:</p>
                    <a style={{color:"#cddc39"}} href="https://www.infura.io/faucet/sepolia " target="blank">https://www.infura.io/faucet/sepolia </a>
                  </div>
                  <div className="mt-3">
                    <p className="fs-13">Use this Token Contract Address to import DAI into your Metamask:</p>
                    <p className="fs-13">0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357<span className="ms-2"><img style={{width:"15px",cursor:"pointer"}} onClick={()=>handleCopyClick('0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357')} src={Copy}></img></span></p>
                  </div>
                  <div>
                    <p className="fs-13">Use this Token Contract Address to import USDC into your Metamask:</p>
                    <p className="fs-13">0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8<span className="ms-2"><img style={{width:"15px",cursor : "pointer"}} onClick={()=>handleCopyClick('0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8')}src={Copy}></img></span></p>
                  </div>
                </div>
              </div>
              {/* <div className="pt-4 mb-5 border-top">
                <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Symbol</p>
                  <p className="fs-13 border-bottom">{config.network_symbol}</p>
                </div>
                <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Address</p>
                  <div className="border-bottom">
                    <a
                      className="fs-13 blue-color "
                      target="_blank"
                      href={`${config.explorerUrl}${config.DAI}`}
                    >
                      {config.USDT}
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-7 pt-3">
            <div className=" py-5 new-trans-page-design sidenav-column-section-2">
              <h4 className="mb-4">Transactions</h4>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={loanHistoryData}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                />
                {/* <div className="table-trans-height">
                  {/* <table class="table text-white tableFixHead">
                    <thead>
                      <tr>
                        <th scope="col">Time</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Hash</th>
                        <th scope="col">Asset</th>
                        <th scope="col">Protocol</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanHistoryData.length > 0 ? loanHistoryData.map((data)=>{
                        return(
                          <>
                            <tr>
                              <td>{moment.utc(data.createdAt).format("YYYY-MM-DD hh:mm:ss")}</td>
                              <td>{data.amount}</td>
                              <td><a href={config.transactionHashUrl + data.transactionHash} target="_blank" className="blue-color">{
                                data.transactionHash.substring(0, 5) + "..." + data.transactionHash.substring(data.transactionHash.length - 4)
                              }</a></td>
                              <td><img className="new-trans-assest-image" src="https://storage.googleapis.com/eigenphi-token-icons/ethereum/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png" alt="logo" /> VUSD</td>
                              <td><img className="new-trans-assest-image" src={aaveLogo} alt="logo" /> AAVE V2</td>
                            </tr>
                          </>
                        )
                      }) : (
                          <>
                            <tr>
                              <td align="center" colspan="5">There is no data is here</td>
                            </tr>
                          </>
                      )}
                    </tbody>
                  </table> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
