let config = {};
console.log("process.env.NODE_ENV :>> ", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  config = {
    BACKEND_URL: "http://localhost:5000/",

    flashLoan_contractAddress: "0x9E62ef04eF57511e8606443199aA1d005F2D4041",
    network_symbol: "DAI",
    DAI: "0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357",
    USDT: "0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8",
    sushiswapRouter: "0x888309AfC8f2d71e2726492E060baB90e91a4151",
    uniswap: "0x6620AFBE86dd0bCD957a67eD21Ff83ba24350F91",
    Uniprice: "0x67DEB114A6226d017931468fb8D0ab2d6050FA1c",
    sushiprice: "0x9b3B0967b3467EcB9d50CFdc74CD2d08b7cC893E",

    explorerUrl: "https://sepolia.etherscan.io/token/",

    sepoliaRpcUrl:
      "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999",
    chainID: 11155111,
    chainName: "Sepolia test network",
    symbol: "SepoliaETH",
    blockExplorerUrls: "https://sepolia.etherscan.io",
    transactionHashUrl: "https://sepolia.etherscan.io/tx/",
    AdminFee: 0.5,
  };
} else if (process.env.NODE_ENV === "Production") {
  config = {
    BACKEND_URL: "https://staging.api.flashloan.d-ecosystem.io/",

    flashLoan_contractAddress: "0x646d40De84da9365C2B8199e277ff23030fd8baD",
    network_symbol: "DAI",
    DAI: "0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357",
    USDT: "0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8",
    sushiswapRouter: "0x888309AfC8f2d71e2726492E060baB90e91a4151",
    uniswap: "0x6620AFBE86dd0bCD957a67eD21Ff83ba24350F91",
    Uniprice: "0x67DEB114A6226d017931468fb8D0ab2d6050FA1c",
    sushiprice: "0x9b3B0967b3467EcB9d50CFdc74CD2d08b7cC893E",

    explorerUrl: "https://sepolia.etherscan.io/token/",

    sepoliaRpcUrl:
      "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999",
    chainID: 11155111,
    chainName: "Sepolia test network",
    symbol: "SepoliaETH",
    blockExplorerUrls: "https://sepolia.etherscan.io",
    transactionHashUrl: "https://sepolia.etherscan.io/tx/",
    AdminFee: 0.5,
  };
} else {
  config = {
    BACKEND_URL: "https://staging.api.flashloan.d-ecosystem.io/",

    flashLoan_contractAddress: "0x646d40De84da9365C2B8199e277ff23030fd8baD",
    network_symbol: "DAI",
    DAI: "0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357",
    USDT: "0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8",
    sushiswapRouter: "0x888309AfC8f2d71e2726492E060baB90e91a4151",
    uniswap: "0x6620AFBE86dd0bCD957a67eD21Ff83ba24350F91",
    Uniprice: "0x67DEB114A6226d017931468fb8D0ab2d6050FA1c",
    sushiprice: "0x9b3B0967b3467EcB9d50CFdc74CD2d08b7cC893E",

    explorerUrl: "https://sepolia.etherscan.io/token/",

    sepoliaRpcUrl:
      "https://sepolia.infura.io/v3/093cf180272947989f95a835644d5999",
    chainID: 11155111,
    chainName: "Sepolia test network",
    symbol: "SepoliaETH",
    blockExplorerUrls: "https://sepolia.etherscan.io",
    transactionHashUrl: "https://sepolia.etherscan.io/tx/",
    AdminFee: 0.5,
  };
}
export default config;
